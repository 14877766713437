<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.update || !subPermission.store"
    />
    <b-card :title="$t('g.basicInformations')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!-- **first name -->
            <b-col md="6">
              <b-form-group :label="$t('g.First name')" label-for="first_name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.first_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** first name -->
            <!-- last name -->
            <b-col md="6">
              <b-form-group :label="$t('g.Last name')" label-for="last_name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.last_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- **last name -->

            <!-- Email -->
            <b-col md="6">
              <b-form-group :label="$t('g.email')" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="form_data.email"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    :placeholder="$t('g.placeholder.enterTheEmailHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Email -->
            <!-- Phone number -->
            <b-col md="6">
              <b-form-group
                :label="$t('g.phoneNumber')"
                label-for="phoneNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone number"
                  rules="required|integer"
                >
                  <b-form-input
                    v-model="form_data.phone"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    :placeholder="$t('g.placeholder.enterThePhoneHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Phone number -->

            <!-- Role -->
            <b-col md="6">
              <b-form-group :label="$t('g.role')" label-for="role">
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.role_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="roles_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Role -->
            <!-- birthDate -->
            <b-col md="6">
              <b-form-group :label="$t('g.birthDate')">
                <b-form-datepicker v-model="form_data.birthdate" />
              </b-form-group>
            </b-col>

            <!-- ** birthDate -->
            <!-- country -->
            <b-col md="6">
              <b-form-group :label="$t('g.Country')" label-for="country">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-select
                    v-model="country"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="country_options"
                    label="position"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** country -->
            <!-- categories -->
            <b-col md="6">
              <b-form-group :label="$t('g.categories')" label-for="categories">
                <v-select
                  v-model="form_data.category_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="categories_options"
                  multiple
                  :reduce="(country) => country.id"
                />
              </b-form-group>
            </b-col>
            <!-- ** categories -->

            <!-- Password -->
            <b-col v-if="!isEdit || (isEdit && role === 'Super Admin')" md="6">
              <b-form-group :label="$t('g.password')" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  :rules="
                    (isEdit && role === 'Super Admin' && null) ||
                    (!isEdit && 'required|min:8|password')
                  "
                >
                  <b-form-input
                    id="a-password"
                    v-model="form_data.password"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    :placeholder="$t('g.placeholder.enterThePasswordHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- confirm password -->
            <b-col
              v-if="!isEdit || (isEdit && role === 'Super Admin')"
              cols="6"
            >
              <b-form-group
                :label="$t('g.confirmPassword')"
                label-for="confirm-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  :rules="
                    (isEdit && role === 'Super Admin' && null) ||
                    (!isEdit && 'required|min:8|password')
                  "
                >
                  <b-form-input
                    id="ac-password"
                    v-model="form_data.password_confirmation"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    :placeholder="
                      $t('g.placeholder.enterTheConfirmPasswordHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Password -->

            <!-- Upload Files  -->
            <b-col md="12">
              <b-form-group>
                <label for="files">{{ $t("g.uploadFiles") }}</label>
                <b-form-file
                  v-model="form_data.path"
                  :placeholder="$t('g.placeholder.chooseAFileOrDropItHere')"
                  :drop-placeholder="$t('g.dropFileHere')"
                  multiple
                />
              </b-form-group>
            </b-col>
            <!-- ** Upload Files -->

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormFile,
  BFormSelect,
  BFormDatepicker,
} from "bootstrap-vue";
import { required, email, phone, password, confirmed, min } from "@validations";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";
import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ContentNotView,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BCard,
    BFormFile,
    BFormDatepicker,
    Loading,
    vSelect,
    Error,
  },
  data() {
    return {
      form_data: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        phone: null,
        password_confirmation: null,
        role_id: null,
        path: [],
        country_id: null,
        country_code: null,
        birthdate: null,
        category_id: [],
      },
      roles_options: [],
      country_options: [],
      categories_options: [],
      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      subPermission: {},
      role: null,
      email: null,
      path: null,
      phone: null,
    };
  },

  computed: {
    country: {
      get() {
        return this.form_data.country_id;
      },
      set(val) {
        this.form_data.country_id = val;
        const isCountry = this.country_options.find(
          (item) => item.value === val
        );
        this.form_data.country_code = isCountry?.code;
      },
    },
  },

  watch: {
    $route(to, from) {
      if (to.name === "AddUser") {
        this.handleWatchRoutePage();
      }
    },
  },

  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.getUserData(this.$route.params.id);
    }
  },

  beforeMount() {
    this.getAllRoles();
    this.getAllCountry();
    this.getAllCategories();
  },

  methods: {
    getAllRoles() {
      this.$http
        .get("admin/roles")
        .then((response) => {
          if (response.status === 200) {
            this.roles_options = response.data.data.map((item) => ({
              value: item.id,
              text: item.name,
            }));
            // this.pageStatus(false, false);

            setTimeout(() => {
              this.pageStatus(false, false);
              store.dispatch("GET_PERMISSION", "users");
              this.subPermission = store.state.permissions.sub;
              this.role = store.state.permissions.role;
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },
    getAllCountry() {
      this.$http
        .get("admin/countries")
        .then((response) => {
          if (response.status === 200) {
            this.country_options = response.data.data.map((item) => ({
              value: item.id,
              text: item.name,
              code: item.code,
            }));
            // this.pageStatus(false, false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },
    getAllCategories() {
      this.$http
        .get("admin/categories")
        .then((response) => {
          if (response.status === 200) {
            this.categories_options = response.data.data.map((item) => ({
              id: item.id,
              label: item.name,
            }));
            // this.pageStatus(false, false);
            // console.log(this.categories_options);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },
    // GET USER DATA if isEdit
    getUserData(id) {
      this.$http
        .get(`admin/users/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data;
            console.log(data);
            this.form_data.email = data?.email;
            this.form_data.first_name = data?.first_name;
            this.form_data.last_name = data?.last_name;
            this.form_data.phone = data?.phone[0]?.phone;
            this.form_data.role_id = data?.roles[0]?.id;
            this.form_data.country_id = data?.country?.id;
            this.form_data.country_code = data?.country?.code;
            this.form_data.path = data?.media ?? [];
            this.form_data.birthdate = data?.birthdate;
            this.form_data.category_id = data?.categories?.map(
              (item) => item.id
            );
            // copy data
            this.email = data?.email;
            this.path = data?.media;
            this.phone = data?.phone[0]?.phone;
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },

    // Update page data when it changes
    handleWatchRoutePage() {
      this.isEdit = false;
      this.$refs.form.reset();
      this.clearForm();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "path" || i === "category_id") {
          form[i] = [];
        }
      }
    },

    // Send the modified data
    submitEdit() {
      if(this.form_data.password === null || this.form_data.password === ''){
        delete this.form_data.password;
        delete this.form_data.password_confirmation;
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          const copyData = { ...this.form_data };

          if (copyData.password === null) {
            delete copyData.password;
            delete copyData.password_confirmation;
          }

          if (copyData.path[0]?.path === this.path[0]?.path) {
            delete copyData.path;
          }

          if (copyData.phone === this.phone) {
            delete copyData.phone;
          }

          let formData = new FormData();
          for (let item in copyData) {
            if (item !== "category_id") {
              formData.append(item, copyData[item]);
            }
          }
          for (let i = 0; i < copyData.category_id.length; i++) {
            formData.append(`category_id[${i}]`, copyData.category_id[i]);
          }

          // call Backend
          this.$http
            .post(`admin/users/${this.currentId}?_method=put`, formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "UsersList" });
              }, 500);
            })
            .catch((err) => {
              console.log(err.response.data.message);
              this.makeToast(
                "danger",
                this.$t("g.send.errorBody"),
                this.$t("g.send.errorTitle")
              );
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit new user
    submitNewAdditon() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          let copyData = { ...this.form_data };

          for (let item in copyData) {
            if (copyData[item] === null) {
              delete copyData[item];
            }
          }

          let formData = new FormData();
          for (let item in copyData) {
            if (item !== "category_id") {
              formData.append(item, copyData[item]);
            }
          }
          for (let i = 0; i < copyData.category_id.length; i++) {
            formData.append(`category_id[${i}]`, copyData.category_id[i]);
          }
          // console.log(this.form_data);

          // call backend - $http is an axios prototype
          this.$http
            .post("admin/users", formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: "UsersList" });
              }, 1000);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      // console.log(this.form_data);

      if (this.isEdit) {
        return this.submitEdit();
      }
      this.submitNewAdditon();
    },
  },
};
</script>
<style>
.loading-div {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
